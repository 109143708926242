import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Company } from '../../_models/company.model';

@Injectable({
  providedIn: 'root',
})
export class InitService {
  private apiUrl = environment.urlApi;

  constructor(private cookieService: CookieService,
    private http: HttpClient) { }

  getExternalReferences(): Observable<Company> {
    let usernameNumber = this.getCompanyIdCookie();
    if (usernameNumber == null)
    {
      usernameNumber = this.getCompanyIdCookieIdEmpresa();
    }
    const origin = 'scm';

    if (usernameNumber == '' || usernameNumber == null) {
      return throwError(() => new Error('No se pudo extraer el número del username del token.'));
    }

    const url = `${this.apiUrl}/Companies/externalreferences/${origin}/${usernameNumber}`;

    return this.http.get<Company>(url).pipe(tap((response) => {
      if (response && response._id) {

        this.cookieService.set('companyId', response._id, 0, '/', environment.cookieDomain, true, 'Lax');
      } else {
        console.error('No se encontró el campo companyId en la respuesta.');
      }
    }),
      catchError(this.handleError)
    )
  }

  getAccessToken(): string {
    return this.cookieService.get('accessToken') || '';
  }

  getCompanyGuidCookie(): string | '' {
    return this.cookieService.get('companyId') || '';
    
  }

  getCompanyIdCookieIdEmpresa(): string | '' {
    let idempresa = this.cookieService.get('IDEMPRESA') || '';

    //decodificar idempresa 
    let XOR: number = 46;
    try {
      const base64Str = idempresa.replace(/\|/g, "+");
      const decodedBytes = Uint8Array.from(atob(base64Str), c => c.charCodeAt(0));
      const originalBytes = decodedBytes.map(byte => byte ^ XOR);
      return new TextDecoder().decode(originalBytes);
    } catch (error) {
      return "";
    }

  }

  getCompanyIdCookie(): string | '' {
    let token = this.getAccessToken();

    const tokenParts = token.split('.');

    const payload = JSON.parse(atob(tokenParts[1]));

    if (payload && payload.username) {
      const match = payload.username.match(/_(\d+)$/);
      return match ? match[1] : null;
    }
    return '';
  }

  private handleError(error: any): Observable<never> {
    console.error('Error en la solicitud HTTP:', error);
    return throwError(() => new Error('Ocurrió un error, por favor inténtelo de nuevo más tarde.'));
  }
}
